.resellerpage {
	position: relative;	
	.bottomBg {
		width: 100%;
		height: 70vw;
		position: absolute;
		bottom: 0;
		left: 0;
		background: {
		    image: url("../../images/reseller/a_mano_mia_pasta_senza_glutine_diventa_rivenditore_bg.png");
		    repeat: no-repeat;
		    position: bottom left;
		    size: 100%;
		}
		z-index: 0;
	}	
}
.ourReseller {
z-index: 10;	
	padding-bottom: 50px;
	h2 {
		font-family: $font-family-serif;
		color: white;
		font-size: 35px;
		text-transform: uppercase;
		margin-bottom: 50px;
	}
	.singleRes {
		color: white;		
		height: 200px;
		.txtRes {
			width: 80%;
			float: left;
			h4 {
				font-weight: bold;
				color: $brand-gold;
			}
			p {
				font-size: 15px;
			}

		}
		.imgRes {
			float: left;			
			display: inline-block;		
			width: 20%;
			img {	
				width: 90%;		
			}			
		}			
		.site {
			font-size: 15px;
			font-weight: 400;
			a {
				color: white;
			}			
		}
	}
}
.resellerTop {
	.descImage {
		max-width: none;
		width: 100%;
	}
	h1 {
		color: white;
		text-transform: uppercase;
		
	}
	h3 {

	}
	p {
		color: white;
	}
	img {
		
	}
	.descReseller {
		margin-top: 60px;
		@include screen('tabletland'){
			margin-top: 0;
		}
		@include screen('large'){
			margin-top: 60px;
		}
		@include screen('full'){
			margin-top: 0;
		}
		h1 {
			@include screen('large'){
				margin-top: 0;
			}
		}
		ul {
			color: white;
			list-style: none;
			
			li {
				&:before {
						position: absolute;					
				    content: '\2022';
				    color: $brand-gold;
				    margin-left: -15px;
				}
			}
		}
		.glutenFreen {
			margin-left: auto;
			margin-right: auto;
			display: block;
			position: relative;		
			@include screen('large'){
				margin-top: 50px;
			}
		}
	}
}
.contactFormReseller {
	color: white;
	.contactHomeContainer {
		padding-top: 115px;
		padding-left: $padding-mobile;
		padding-right: $padding-mobile;
		@include screen('tabletport'){
			padding-top: 0px;
		}
		.textContact {
			padding-bottom: 50px;
			h3 {
				color: $brand-gold;
			}
		}
		.formContact {
			padding-bottom: 250px;
			input, textarea {
				background-color: transparent;
				border: none;
				border-radius: unset;
				box-shadow: none;
				border-bottom: 2px solid #fff;				
				color: white;
				padding-left: 0;
				&:focus {
					color: white;
				}
			}
			textarea {
				margin-top: 50px;
			}
			select.select-style {
				text-transform: uppercase;
				color: white;
		    padding-left: 0;
		    border: none;
		    box-shadow: none;
		    background-color: transparent;
		    background-image: none;
		    -webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border-bottom: 2px solid #fff;
				border-radius: unset;
				&:focus {
					outline: none;						
				}
			}			
			input::placeholder, textarea::placeholder {
			  color: white;
			  text-transform: uppercase;
			  padding-left: 0;
			}				    
			a {
					color: $brand-gold;
			}			
			button[type='submit']	{
				@extend button;
				float: right;
				background-color: $brand-gold;
				color: black;	
				&:hover {
					background-color: $gray-darker;
					color: $brand-gold;
				}
			}			
		}	
	}
}
.checkbox {
	input[type="checkbox"] {
		width: 20px;
		height: 20px;
		margin-left: -30px !important;
	}
	input[type="checkbox"]:not(old) {
	  width: 28px;
	  margin: 0;
	  padding: 0;
	  opacity: 0;
	  margin-right: -30px;
	}

	input[type="checkbox"]:not(old) {
	  margin-right: 0px;
	}

	input[type="checkbox"]:not(old) + label {
	  display: inline-block;
	  margin-left: 0px;
	  padding-left: 28px;
	  background: url("../../images/checks.png") no-repeat 0 0;
	  line-height: 24px;
	}

	input[type="checkbox"]:not(old) + label {
	  display: inline-block;
    margin-left: -18px;
    padding-left: 38px;
	  background: url("../../images/checks.png") no-repeat 0 0;
	  line-height: 24px;
	}

	input[type="checkbox"]:not(old):checked + label {
	  background-position: 0 -48px;
	}	
}