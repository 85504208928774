.productpage {
	position: relative;
	background-color: $gray-darker;	
	.halfBg {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 0;
		@include screen('tabletland'){
			background: {
			    image: url("../../images/product/a_mano_mia_pasta_senza_glutine_img_bg_top.png");
			    repeat: no-repeat;
			    position: top right;
			    size: 100%;
			}			
		}
	}
}
.container-1250 {
	@extend .container;
	padding-left: 0;
	padding-right: 0;
	@include screen('large'){
		width: 1250px;
	}
}
.firstViewProduct {	
	position: relative;	
	z-index: 50;
	padding-top: 50px;
	padding-bottom: 100px;
	@include screen('tabletport'){
		padding-bottom: 50px;
	}
	@include screen('tabletland'){
		padding-bottom: 100px;
	}
	.centerColumn {
		float: left;			
		position: relative;
		margin-top: 20px;
		@include screen('tabletport'){
			margin-top: 0;
			padding-left: 15px;
			padding-right: 15px;
			width: 50%;		
		}
		@include screen('large'){
			width: 500px;
		}
		.circleMin {
			@include circle(70px, $brand-gold, 1px);
			text-align: center;
			padding-top: 16px;
			text-transform: uppercase;
			position: absolute;
			top: 20px;
			right: 35px;
			line-height: 17px;
		}			
		h1 {
			position: absolute;
			bottom: 20px;
			left: 0;right: 0;
			text-align: center;
			color: white;
			font-family: $font-handwrite;			
			@include screen('large'){
				font-size: 52px;
			}			
		}
	}
	.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;		
	}	
	#firstColumn {
		@include screen('tabletport'){
			margin-top: 50px;
		}
		@include screen('tabletland'){
			margin-top: 70px;
		}
	}
	.sideColumn {
		float: left;
		padding-left: 30px;
		padding-right: 30px;
		margin-top: 50px;
		width: 100%;
		@include screen('tabletport'){
			width: 50%;
			margin-top: 0;
			padding-left: 15px;
			padding-right: 15px;
		}
		@include screen('large'){
			width: 360px;
		}		
		.descCot {
			.titleCot {
				color: $brand-gold;
				text-transform: uppercase;
				
			}
			p {
				color: white;				
			}			
			.glutenFreeSentence {
				font-size: 15px;
				margin-top: 60px;
				margin-bottom: 60px;
				font-family: $font-handwrite;
				color: $brand-gold;
				font-size: 26px;
				@include screen('large'){
					font-size: 26px;
				}
			}
		}
		.iconProduct {
			@include screen('tabletport'){
				width: (100%/3);
				float: left;				
			}
			@include screen('large'){
				width: 100%;
				float: none;				
			}
			.singleIllustration {
				color: white;
				font-family: $font-handwrite;
				float: right;
				@include screen('tabletport'){
					float: none;					
				}
				@include screen('tabletland'){
					
				}
				@include screen('large'){
					width: 30%;
					float: right;
				}
				img {
					margin-left: auto;
					margin-right: auto;
					position: relative;
					display: block;
					@include screen('large'){

					}
				}
				.label {
					margin-top: 20px;
					text-align: center;
				}
			}
			&:nth-child(even){
				.singleIllustration {
					float: left;
					@include screen('tabletport'){
						float: none;						
					}
					@include screen('large'){
						float: left;
					}

				}
			}
		}		
	}	
	.fullColumn {
		@include screen('tabletport'){
			width: 100%;
		}
		@include screen('large'){
			width: 360px;			
		}
	}
}
.product {	
	position: relative;	
	z-index: 50;
	.bgProduct {
		background-color: #fff;
		padding-top: 50px;
		@include screen('large'){
			padding-top: 0;
		}
	}
	.containerProd {		
		@include screen('large'){
			padding-top: 100px;
			padding-bottom: 100px;
	    padding-left: 70px;
	    padding-right: 20px;			
		}
		@include screen('full'){
			padding-left: 15px;
			padding-right: 15px;
		}
		.single {
			padding: 20px;
			img {
				margin-left: 0px;				
				display: block;
				position: relative;
				@include screen('tabletland'){
					float: left;						
				}				
				@include screen('large'){
										
					margin-left: auto;
					margin-right: auto;
					position: relative;
					display: block;					
				}	
			}
			.titleIng {				
				@include screen('large'){
				}
			}
			p {
				width: 80%;			
				@include screen('large'){
				}
			}
			&:nth-child(even){
				img {
					float: right;
					@include screen('tabletport'){
						float: left;						
					}
					@include screen('large'){
						
					}					
				}		
				.titleIng {
					text-align: right;					
					@include screen('tabletport'){
						text-align: left;
					}					
				}
				p {
					width: 80%;
					float: right;	
					text-align: right;
					@include screen('tabletport'){
						float: none;
						text-align: left;						
					}											
					@include screen('large'){

					}
				}				
			}
		}
	}
}
.recepies {
	position: relative;	
	z-index: 50;
	@include screen('large'){
		padding-top: 100px;
	}	
	.singleRecepie {
		margin-top: 10px;
		@include screen('large'){
			margin-top: 90px;
		}
		.descRecepie {
			padding: 30px;
			@include screen('large'){
				padding: 85px;
			}
			h4 {
				font-family: $font-handwrite;
				color: $brand-gold;
				font-size: 34px;
			}
			p {
				color: white;
			}
			img {
				width: 350px;
				margin-top: 50px;
				margin-left: auto;
				margin-right: auto;
				position: relative;				
				display: none;
				
				@include screen('tabletland'){
					display: block;
				}
				@include screen('large'){
					width: 260px;
				}
			}
		}
		.cardRecepie {
			background-color: #fff;
			img {
				width: 100%;
			}
			.nameRecepie {
				padding: 30px;
				padding-bottom: 125px;
				@include screen('large'){
					padding: 100px;
				}
				h4 {
					font-family: $font-handwrite;
					color: $brand-gold;
					font-size: 34px;					
				}
				p {

				}
				img {
					width: 100px;
					position: absolute;
					right: 20px;
					bottom: 20px;
				}
			}
		}
	}
}