body {
    font-family: $font-family-serif;
    font-size: 17px;    
    background-color: $gray-darker;
    &:focus {
        outline: transparent;
    }
}
h1 {
    font-size: 35px;
    @include screen('tabletport'){
        font-size: 45px;
    }    
}
h2, h3 {
    font-family: 'night_wind_sent';
    font-size: 28px;
    color: $brand-gold;
    @include screen('tabletport'){
        font-size: 35px;
    }
}
button {    
    padding: 12px 17px;
    color: white;
    border-radius: 25px;
    border: none;
    text-transform: uppercase;    
    position: relative;
    margin-top: 30px;
    display: block;
    font-size: 13px;
}
.b-center {
    margin-left: auto;
    margin-right: auto;
}
.b-left {
    float: left;    
}
.b-right {
    float: right;    
}
.gold {
    background-color: $brand-gold;
    color: $gray-darker;
    transition: .3s color ease-in-out, .3s background-color ease-in-out;
    &:hover {
        background-color: $gray-darker;
        color: $brand-gold;        
    }
}
.black {
    background-color: $gray-darker;
    color: $brand-gold;
    transition: .3s background-color ease-in-out;
    &:hover {
        background-color: #5C5650;   
    }
}
img {
    max-width: 100%;
}

p {
    //text-align: justify;
    
}
a, button {
    outline: transparent
}
ul {
    -webkit-padding-start: 0px;
    -moz-padding-start: 0px;
    -o-padding-start: 0px;
    padding-start: 0px;
}

footer {}

a {
    &:hover {
        text-decoration: none;
        color: $brand-gold;
    }
    &:focus {
        outline: transparent;
    }
}
