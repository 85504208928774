.relativePos {
    position: fixed;
    z-index: 100;  
    width: 100%;
    display: none;
    @include screen('large'){
        display: block;        
        z-index: 110;
    }    
    @include screen('fullhd'){
        width: 100%;
        max-width: 1400px;  
        left: 50%;
        margin-left: -700px;
    }    
}
.lang {
    background-color: red;
    color: white;
    padding: 20px 5px;
    display: inline-block;    
    position: absolute;
    right: 0;
    a {
        text-transform: uppercase;
        color: white;        
    }
}
.langMobile {    
    display: block;
    @include screen('large'){
        display: none;
        
    }
    ul {
        list-style: none;       
        padding-top: 15px;
        padding-left: 15px;           
        li {
            color: $brand-gold;   
            padding: 10px;
            font-weight: 600;            
            a {
                color: $brand-gold;    
            }
        }
    }
}
header {
    width: 100%;
    position: fixed;
    z-index: 101;
    .logo {
        display: block;
        width: 217px;
        height: 70px;
        overflow: hidden;
        position: absolute;
        transition: .5s height;
        @include screen('tabletport'){
            width: 322px;
            height: 100px;
        }        
        @include screen('large'){
            display: none;            
        }
        img {            
            position: absolute;
            top: 10px;
            left: 15px;
            width: 200px;
            z-index: 101;
            @include screen('tabletport'){
                width: 302px;
            }            
        }
    }
}
.hamburger {
    position: absolute;
    right: 0;
    z-index: 102;
    
    @include screen('tabletport'){
        padding: 35px 35px;
    }    

    @include screen('large'){
        display: none;       
    }
}
.scroll-menu {
    background-color: $body-bg;
    width: 100%;
    height: 0;
    transition: .4s height cubic-bezier(.33,.01,0,1.07);
    @include screen('large'){
        width: 100%;
    }
}
menu {
    width: 100%;
    height: 0;
    z-index: 100;    
    margin-top: 0;  
    position: fixed;
    top: 0;left: 0;    
    overflow: hidden;
    text-transform: uppercase;
    background-color: $gray-darker;
    transition: 1s height cubic-bezier(.33,.01,0,1.07); 
    @include screen('tabletport'){

    }
    @include screen('tabletland'){
        //max-width: 900px;
    }
    @include screen('large'){
        //max-width: 1200px;
        margin: 0 auto;
        width: 100%;        
        background-color: transparent;        
        height: inherit;    
        overflow: inherit;
    }
    .shape-menu {
        width: 143%;
        max-width: none;        
        position: absolute;
        top: -80px;
        right: -280px;
        z-index: -1;
        opacity: .7;
        @include screen('tabletport'){
            width: 101%;
        }
        @include screen('tabletland'){
            width: 71%;
            right: -170px;
        }
        @include screen('large'){
            display: none;            
        }
    }  
    .object-menu {
        width: 105%;
        max-width: none;        
        position: absolute;
        top: 0px;
        right: -157px;
        z-index: -1;
        opacity: .7;  
        @include screen('tabletport'){
            width: 75%;
        }
        @include screen('tabletland'){
            right: 0;
            width: 55%;
        }
        @include screen('large'){
            display: none;            
        }      
    }    
    .mainMenu {
        list-style: none;
        display: block;
        width: 100%;
        padding-top: 120px;
        padding-left: 15px;     
        transition: .6s padding;
        z-index: 10;
        @include screen('tabletport'){
            padding-top: 180px;
        }
        @include screen('large'){
            max-width: 1200px;
            display: table;            
            width: auto;
            margin: 0 auto;            
            padding: 35px 0;
            text-align: center;            
        }      
        li {
            padding: 10px;
            font-weight: 600;
            transition: .6s padding;
            @include screen('tabletport'){                
                padding: 20px;         
            }
            @include screen('tabletport'){
                padding: 15px 12px;
                //font-size: 13px;
                font-size: 25px;
            }
            @include screen('tabletland'){
                padding: 15px;
            }
            @include screen('large'){
                font-weight: 400;
                padding: 29px 13px;
                float: left;
                display: table-cell;
                vertical-align: middle;  
                font-size: 17px;                     
            }
            @include screen('full'){
                padding: 35px 20px;   
            }
            a {
                text-decoration: none;
                color: white;
                @include screen('large'){
                    
                }
            }
            &:nth-child(3) {
                padding-top: 0;
                padding-bottom: 0;
                display: none;                
                img {
                    max-width: none;
                    transition: .3s width;     
                    @include screen('large'){
                        width: 310px;
                    }                  
                    @include screen('full'){
                        width: 370px;
                    }             
                }
                @include screen('large'){
                    display: block;   
                }
            }
            .containerMenu {
                @include screen('large'){
                    max-width: 920px;
                    margin-left: auto;
                    margin-right: auto;                        
                }            
            }
            .titleProduct {
                text-transform: uppercase;
                color: $brand-gold; 
                text-align: left;  
                margin-bottom: 10px;                 
            }
            .subMenu {                
                width: 100%;
                max-width: none;
                left: 0;                
                background-color: $gray-darker;
                @include screen('large'){
                    position: fixed;
                    margin-top: 45px;
                }
                ul {                    
                    float: left;   
                    padding-top: 20px;
                    padding-bottom: 20px;
                    width: 100%;
                    list-style: none;
                    @include screen('tabletport'){
                        width: 50%;
                    }
                    @include screen('large'){
                        width: (100%/3);
                        padding-top: 100px;
                        padding-bottom: 100px;  
                    }                                   
                    li {
                        text-transform: initial; 
                        width: 50%;
                        float: left;  
                        padding-top: 6px !important;
                        padding-bottom: 6px !important;
                        padding-left: 0;
                        text-align: left;
                        &:nth-child(3) {
                            display: inherit;            
                        }                   
                    }
                }
                .glutenFree {
                    @include screen('large'){
                        float: left;                        
                        width: (100%/3);
                        padding-top: 100px;
                        padding-bottom: 100px;  
                    }                  
                }
            }            
        }
    }
}
.menuDown {
    background-color: white;
    height: 66px;
    @include screen('tabletport'){
        height: 77px;
    }
    @include screen('large'){
        background-color: $gray-darker;        
    }
    @include screen('large'){
        height: auto;
    }
}
.menuDownScroll {
    height: 66px;       
    @include screen('tabletport'){
        height: 86px;
    }
    @include screen('large'){
        background-color: $gray-darker;
        height: 66px;
    }
    @include screen('full'){
        height: 86px;
    }
    ul {
        @include screen('large'){
            padding: 5px 0;            
        }
        li {
            @include screen('large'){
                padding: 17px 13px;                
            }
            @include screen('full'){
                padding: 32px 10px;   
            }     
            &:nth-child(3) {
                img {     
                    @include screen('large'){
                        width: 220px;
                    } 
                    @include screen('full'){
                        width: 310px;
                    }
                }                
            } 
            .subMenu {
                margin-top: 25px;
            }                              
        }
    }    
}
.menu-active {
    height: 100%;
    background-color: $gray-darker; 
    overflow: scroll;
}
.hamburger-color {
    .hamburger-inner {
        &,
        &::before,
        &::after {
            background-color: #000;
        }
    }    
}