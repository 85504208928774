$padding-mobile: 30px;
.homepage {
	position: relative;
	background-color: $gray-darker;	
	.halfBg {
		width: 100%;
		height: 70vw;
		position: absolute;
		bottom: 50%;
		left: 0;
		z-index: 0;
		@include screen('tabletland'){
			background: {
			    image: url("../../images/home/a_mano_mia_pasta_senza_glutine_003.png");
			    repeat: no-repeat;
			    position: center left;
			    size: 100%;
			}			
		}		
	}
	.bottomBg {
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 0;
		@include screen('tabletland'){
			background: {
			    image: url("../../images/home/a_mano_mia_pasta_senza_glutine_002.png");
			    repeat: no-repeat;
			    position: bottom left;
			    size: 100%;
			}
		}
	}
}
.firstViewHome {
	position: relative;
	z-index: 50;
	.bgslider {
		position: absolute;
		top: 0;bottom: 0;right: 0;left: 0;
		background-color: #000;
		opacity: .4;
		z-index: 2;
	}
	.slider {	
		position: absolute;
		height: 100%;
		width: 100%;
		div {
			background: {
			    repeat: no-repeat;
			    position: center center;
			    size: cover;
			}				
			width: 100%;
			height: 100%;
		}
		z-index: 0;
	}
	.descHome {
		position: absolute;		
		left: 0;right: 0;
		bottom: 20px;	
		z-index: 10;	
		color: #fff;
		@include screen('tabletport'){
			bottom: 60px;
		}
		@include screen('tabletland'){
			bottom: 20px;
		}
		@include screen('large'){
			bottom: 120px;
		}
		h1 {
			margin-top: 0;
			text-transform: uppercase;			
		}
		h3 {
			
		}
		.descSide {
			display: none;
			@include screen('tabletport'){
				display: block;				
			}
			.title {
				color: $brand-gold;
				text-transform: uppercase;				
			}
			p {}
		}
		.pagingInfo {
			color: white;
			margin-top: 20px;
			.goldTxt {
				color: $brand-gold;
			}
		}		
		button.black {
			margin-top: 20px;
		}
	}
}
.home {
	z-index: 50;
	position: relative;
	
	.homeCont {
		background-color: #fff;
		@include screen('large'){
			padding-bottom: 50px;
		}
		@include screen('full'){
			padding-bottom: 100px;
		}		
		.descWho {
			padding: $padding-mobile;
			@include screen('tabletport'){
				padding: 60px;
			}
			@include screen('tabletland'){
				padding: 40px;
			}
			@include screen('large'){
				padding: 70px;
			}
			@include screen('full'){
				padding: 50px 100px;
			}
			@include screen('fullhd'){
				padding: 85px;
			}			
		}		
		.descImage {
			margin-left: auto;
			margin-right: auto;
			display: block;
			position: relative;				
			@include screen('tabletland'){
				margin-left: inherit;
				margin-right: inherit;
			}
		}		
		.centerImage {					
			display: none;		
			img {
				position: relative;	
				margin-left: auto;
				margin-right: auto;
				display: block;				
			}	
			@include screen('tabletland'){
				display: block;
				top: 0;
			}
			@include screen('large'){
				margin-top: -50px;
			}
			@include screen('fullhd'){
				margin-top: -250px;				
			}
			
		}
	}
}
		.resellerHome {
			background-color: transparent;
			@include screen('tabletland'){
				display: table;
			}
			@include screen('large'){
				margin-top: 0px;				
				
			}
			@include screen('fullhd'){
				//margin-top: -200px;
			}
			div {
				@include screen('tabletland'){
					display: table-cell;
					vertical-align: middle;
					float: none;					
				}
			}
			.logoResellerHome {
				padding: $padding-mobile;
				padding-bottom: 0;
				margin: 0 auto;
				@include screen('tabletport'){
					padding: 90px;
				}
				img {
					margin: 0 auto;
					display: block;
					position: relative;
					width: 270px;
				}
			}
			.descResellerHome {
				padding: $padding-mobile;
				@include screen('tabletport'){
					padding: 0 90px 90px;					
				}			
				@include screen('tabletland'){
					padding: 90px 30px;
				}
				p {
					color: white;
				}
			}
		}
.videoHome {
	//padding-bottom: 100px;
	z-index: 50;
	position: relative;
	iframe {

	}
	@include screen('tabletport'){
		padding-bottom: 100px;
	}
}
.productHome {
	//background-color: $gray-darker;
	z-index: 50;
	position: relative;
	
	.productHomeCont {
		background-color: white;
		.productimgTop {
			max-width: none;
			width: 100%;
		}
		.productHomeContDesc {
			padding: $padding-mobile;
			@include screen('tabletport'){
				padding: 60px;
			}
			@include screen('tabletland'){
				padding: 40px;
			}
			@include screen('large'){
				padding: 70px;
			}
			@include screen('full'){
				padding: 50px 100px;
			}
			@include screen('fullhd'){
				padding: 100px;
			}
			.illustration {
				display: table;
				div {
					display: table-cell;
					vertical-align: middle;					
				}
				label {
					font-family: 'night_wind_sent';
					font-size: 25px;
					padding-left: 20px;
				}
			}
		}
		.listProduct {
			@include screen('tabletport'){
				padding-top: 60px;
			}
			@include screen('large'){
				padding-top: 0;
			}
			.paddingProductHome {
				@include screen('tabletport'){
					padding: 30px;
				}
				@include screen('tabletland'){
					padding: 50px;
				}
				@include screen('large'){
					padding: 70px;					
				}
				@include screen('fullhd'){
					padding: 100px;
				}				
			}
			.floatRight {
				float: right;	
				button {
					margin-top: -92px;
					margin-left: auto;
					margin-right: auto;					
					@include screen('tabletport'){
						margin-top: 30px;
						float: left;
					}
				}								
			}
			.floatLeft {
				float: left;				
				button {
					margin-top: -92px;
					margin-left: auto;
					margin-right: auto;					
					@include screen('tabletport'){
						margin-top: 30px;
						float: right;						
					}
				}					
			}
			.leftsize {
				width: 100%;
				@include screen('tabletport'){
					width: 375px;
				}
				@include screen('tabletland'){
					width: 470px;
				}
				@include screen('large'){
					width: 400px;
				}
				@include screen('fullhd'){
					width: 500px;
				}							
			}
			.rightsize {	
				width: 100%;	
				@include screen('tabletport'){
					width: 375px;
				}					
				@include screen('tabletland'){
					width: 485px;
				}				
				@include screen('large'){
					width: 585px;
				}
				@include screen('fullhd'){
					width: 700px;
				}			
			}						
			.productContainer {
				padding-bottom: $padding-mobile;
				@include screen('tabletport'){
					padding-bottom: 0;
				}
				.descProductHome {
					padding: 80px $padding-mobile 60px $padding-mobile;
					@include screen('tabletport'){
						padding: 0;
					}
					.icon {
						position: relative;
						width: 100%;
						img {
							float: right;							
						}
					}
					.titleDesc {
						font-family: 'night_wind_sent';
						width: 100%;
						font-size: 25px;
						@include screen('large'){
							//font-size: 25px;
						}
					}
					p {
						width: 100%;
						text-align: justify;
						@include screen('tabletport'){
							padding-bottom: 80px;
						}
					}
				}				
				.productContainerInner {		
					position: relative;	
					text-align: center;															
					.sliderProduct {

					}				
					.titleProdSlide {
						width: 100%;
						position: absolute;
						bottom: 110px;		
						font-family: 'night_wind_sent';								
						color: white;
						font-size: 40px;
						@include screen('tabletport'){
							bottom: 20px;
							font-size: 55px;					
						}
						@include screen('large'){
						}					
					}
					.slideProductHome {

					}
				}
			}
			.closePhoto {
				img {
					width: 100%;
					max-width: none;
					margin-top: -1px;
					@include screen('tabletport'){
						margin-top: 70px;
					}					
					@include screen('tabletland'){
						margin-top: 0;
					}
				}				
				@include screen('tabletland'){					
					float: right;
					width: 485px;
				}
				@include screen('large'){
					float: right;
					width: 585px;
					padding: 100px 69px 0;		
					margin-top: -200px;
				}				
				@include screen('full'){	
					margin-top: -280px;				
				}
				@include screen('fullhd'){									
					width: 700px;
					padding: 100px 100px 0;	
					margin-top: -440px;
				}
			}
		}
	}
}
.pagesGen {
	color: white;
}