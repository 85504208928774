@charset "UTF-8";
// Settings
// ==================================================
$hamburger-padding-x: 20px !default;
$hamburger-padding-y: 20px !default;
$hamburger-layer-width: 30px !default;
$hamburger-layer-height: 4px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: white !default;
$hamburger-layer-border-radius: 0px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: ( 3dx, 3dx-r, 3dy, 3dy-r, arrow, arrow-r, arrowalt, arrowalt-r, boring, collapse, collapse-r, elastic, elastic-r, emphatic, emphatic-r, slider, slider-r, spring, spring-r, stand, stand-r, spin, spin-r, squeeze, vortex, vortex-r) !default;
// Base Hamburger (We need this)
// ==================================================
@import "base";
// Hamburger types
// ==================================================
@import "types/3dx";
@import "types/3dx-r";
@import "types/3dy";
@import "types/3dy-r";
@import "types/arrow";
@import "types/arrow-r";
@import "types/arrowalt";
@import "types/arrowalt-r";
@import "types/boring";
@import "types/collapse";
@import "types/collapse-r";
@import "types/elastic";
@import "types/elastic-r";
@import "types/emphatic";
@import "types/emphatic-r";
@import "types/slider";
@import "types/slider-r";
@import "types/spring";
@import "types/spring-r";
@import "types/stand";
@import "types/stand-r";
@import "types/spin";
@import "types/spin-r";
@import "types/squeeze";
@import "types/vortex";
@import "types/vortex-r";
// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
