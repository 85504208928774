.contactHome {
	color: white;
	.contactHomeContainer {
		padding-top: 50px;
		padding-bottom: 20px;
		padding-left: $padding-mobile;
		padding-right: $padding-mobile;
		@include screen('tabletport'){
			padding-top: 115px;
			padding-bottom: 100px;
		}
		.textContact {
			padding-bottom: 50px;
			h3 {
				color: $brand-gold;
			}
		}
		.formContact {
			padding-bottom: 110px;
			@include screen('large'){
				
			}
			input, textarea {
				background-color: transparent;
				border: none;
				border-radius: unset;
				box-shadow: none;
				border-bottom: 2px solid #fff;				
				color: white;
				padding-left: 0;
				&:focus {
					color: white;
				}
			}
			input::placeholder, textarea::placeholder {
			  color: white;
			  text-transform: uppercase;
			  padding-left: 0;
			  
			}		
			a {
					color: $brand-gold;
			}			
			button[type='submit']	{
				@extend button;
				float: right;
				background-color: $brand-gold;
				color: black;
				transition: .3s background-color ease-in-out, .3s color ease-in-out;
				&:hover {
					background-color: $gray-darker;
					color: $brand-gold;
				}		
			}			
		}	
	}
}