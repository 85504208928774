$padding-large: 100px;
.aboutpage {
	position: relative;
	background-color: $gray-darker;	
	.bottomBg {
		width: 100%;
		height: 70vw;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 0;
		@include screen('tabletland'){
			background: {
			    image: url("../../images/about/a_mano_mia_pasta_senza_glutine_012.png");
			    repeat: no-repeat;
			    position: bottom left;
			    size: 100%;
			}
		}
	}
}
.firstViewAbout {
	@include screen('tabletland'){
		padding-bottom: 50px;
	}
	h1 {
		color: white;
		text-transform: uppercase;		
	}
	p {
		color: white;
	}
	button {
		float: left;		
	}
	button.contact {
		margin-right: 20px;
		a {			
		}		
	}
	button.reseller {
		background-color: white;
		color: $gray-dark;
		margin-bottom: 80px;
		@include screen('tabletport'){
			
		}
		@include screen('tabletland'){
			margin-bottom: 0;
		}
	}
	.centerImage {
		position: relative;			
		margin-left: auto;
		margin-right: auto;
		display: none;			
		@include screen('tabletland'){
			display: block;
			top: 0;
		}
		@include screen('large'){
			top: -50px;
		}
		@include screen('full'){
			
		}
		@include screen('fullhd'){
			top: -200px;
		}
		
	}	
}
.secondViewAbout {
	@extend .firstViewAbout;
	padding-bottom: 50px;
	@include screen('large'){
		padding-top: 100px;
		padding-bottom: 100px;
	}
	h3 {
		font-family: $font-family-serif;
		text-transform: uppercase;		
	}
}
.descAbout {
	position: relative;
	z-index: 50;
	.bgDescAbout {
		background-color: #fff;
		.txtAbout {
			padding: $padding-mobile;			
			@include screen('large'){
				display: block;				
				padding: $padding-large;
			}
		}
		h3 {
			font-family: $font-family-serif;
			text-transform: uppercase;	
		}			
		.imageSvg {
			margin-left: auto;
			margin-right: auto;
			display: block;
			position: relative;
			margin-bottom: 50px;
			@include screen('tabletport'){
				margin-bottom: 0;
			}
			
		}
		.imageClose {
			float: right;
			@include screen('tabletland'){
				padding-top: 40px;
			}
			@include screen('large'){
				margin-top: 50px;
				padding-left: 96px;
			}			
		}
	}
}
.whyGlutenFree {
	position: relative;
	z-index: 50;
	.descGluten {
		padding: $padding-mobile;
		@include screen('large'){
			padding: $padding-large;
		}		
		h3 {
			font-family: $font-family-serif;
			text-transform: uppercase;	
		}			
		p {
			color: white;
			@include screen('large'){				
				// padding-left: $padding-large;
				// padding-right: $padding-large;
				column-count: 2;
				column-gap: 200px;
			}	
		}	
	}
}