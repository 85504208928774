footer {
	color: white;	
	padding-top: 20px;
	padding-bottom: 100px;
	padding-left: 20px;
	padding-right: 20px;
	@include screen('tabletport'){
		padding-top: 100px;
		padding-left: 0;
		padding-right: 0;
	}
	@include screen('tabletland'){
		
	}
	a {
		color: white;
	}
	.social {
		text-transform: uppercase;		
		margin-top: 50px;
		text-align: left;
		@include screen('tabletport'){
			margin-top: 0;
			text-align: right;			
		}		
	}	
	ul {
		list-style: none;		
		@include screen('tabletport'){
			float: right;			
		}
		li {
			float: left;
			padding-right: 10px;
			margin-top: 20px;
			@include screen('tabletport'){
				padding-left: 10px;				
			}
			&:first-of-type {				
				@include screen('tabletport'){
					border-right: 2px solid #fff;
				}
			}
			&:last-of-type {
				padding-right: 0;
			}
			a {
				color: white;
			}
		}
	}
}