.openPage {
    opacity: 1 !important;
}

.nopadding {
    padding: 0;
}

.nomargin {
    margin: 0;
}

.clear {
    clear: both;
}
.container-1400 {
	@extend .container;
	@include screen('fullhd'){
		width: 1400px;
	}	
}
.p-t-160 {
	padding-top: 90px;
	@include screen('tabletport'){
		padding-top: 130px;
	}
	@include screen('large'){
		padding-top: 160px;
	}	
}
.p-t-0 {
	padding-top: 0 !important;
}
.hide {
	display: none;	
}
.fix {
	overflow: hidden;			
	@include screen('large'){
		
	}
}
a.readmore {
	color: $brand-gold;
}
.goldTxt {
	color: $brand-gold;	
}